<template>
    <span v-if="value === 1" class="text-muted">Borrador</span>
    <span v-if="value === 2" class="text-primary">Generado</span>
    <span v-if="value === 3" class="text-success">Publicado</span>
</template>

<script>
export default {
    name: 'DocumentStatus',
    props: {
        value: { type: Number, required: true },
    },
};
</script>

<style></style>
